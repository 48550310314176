import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { mobilizeiSubscriptionsService } from '../../../services/mobilizei/subscriptions.service';
import BillingLayout from '../../../layouts/billing';
import Step1 from './Step1';
import Step2 from './Step2';
import CreditCardStep from './CreditCardStep';
import BankSlipData from '../../../components/billing/BankSlipData';
import PixData from '../../../components/billing/PixData';
import { toast } from "react-toastify";
import { usePlan } from '../../../contexts/plan.context';
import { Box } from '@mui/material';
import { useUser } from '../../../contexts/user.context';
import { useNavigate } from 'react-router-dom';
import { storeStatus } from '../../../utils/constants';
import { useForm } from 'react-hook-form';

export default function Subscription() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [subscription, setSubscription] = useState({});
    const { plan } = usePlan();
    const { user } = useUser();

    useEffect(() => {
        if (user?.store?.status === storeStatus.ACTIVE) {
            navigate('/')
            return;
        }
    }, [user]);

    const { control, getValues, handleSubmit, setValue, watch, register, formState: { errors } } = useForm({
        defaultValues: {
            cpfCnpj: '',
            email: '',
            name: '',
            phone: '',
            paymentMethod: 'credit_card',
            planId: plan,
            zipCode: '',
            number: '',
            street: '',
            city: '',
            state: '',
            district: '',
            complement: '',
            creditCardToken: '',
            installments: 1,
            creditCard: {
                number: '',
                cvv: '',
                name: '',
                expiration: ''
            },
        }
    });

    const watchEmail = watch('email', '');
    const watchPaymentMethod = watch('paymentMethod', 'credit_card');

    const changeStep = step => {
        setCurrentStep(step)
        window.scrollTo(0, 0);
    }

    async function onSubmit(creditCardToken) {
        setLoading(true);

        // Crete a 'data' object without the creditCard attribute (so we don't POST the credit card data)
        const { creditCard, ...data } = getValues();

        await mobilizeiSubscriptionsService.create({ ...data, creditCardToken })
            .then(response => {
                setSubscription(response);
                changeStep(3);
            })
            .catch(error => {
                let message = 'Não foi possível realizar a ativação da sua conta. Por favor, confira os seus dados e tente novamente.';

                const errorCode = _.get(error, 'error.code', '');

                if (errorCode === '2142') {
                    message = _.get(error, 'error.message', message);
                    toast.error(
                        <div>
                            Não foi possível processar o pagamento. <br /><br />
                            {message} <br /><br />
                            Por favor, confira os seus dados e tente novamente.
                        </div>
                    );
                } else {
                    toast.error(`${message} (#${errorCode})`)
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <BillingLayout fullScreen>
            <Box paddingY={2}>
                {currentStep === 1 &&
                    <Step1
                        control={control}
                        watch={watch}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        goToStep2={handleSubmit(() => changeStep(2))}
                    />
                }

                {currentStep === 2 &&
                    <Step2
                        watch={watch}
                        goToPreviousStep={() => changeStep(1)}
                        goToNextStep={onSubmit}
                        loading={loading}
                        setValue={setValue}
                        setLoading={setLoading}
                        register={register}
                    />
                }

                {currentStep === 3 && watchPaymentMethod === 'credit_card' &&
                    <CreditCardStep watch={watch} subscription={subscription} />
                }

                {currentStep === 3 && watchPaymentMethod === 'bank_slip' &&
                    <BankSlipData
                        title="Pagamento via boleto"
                        text={<>O acesso à sua conta será liberado após a compensação do boleto, em até 3 dias úteis. Te manteremos informado a respeito do status do pagamento através do e-mail <strong>{watchEmail}</strong>.</>}
                        disclaimer="Próximo da data de vencimento você irá receber no seu e-mail um link para efetuar a renovação da sua assinatura. Utilizando este link, você poderá efetuar um novo pagamento via boleto ou então utilizar algum outro método de pagamento."
                        invoice={_.get(subscription, 'invoice', {})}
                    />
                }

                {currentStep === 3 && watchPaymentMethod === 'pix' &&
                    <PixData
                        title="Pagamento Pix"
                        text={<>Efetue o pagamento utilizando os dados abaixo. Te avisaremos pelo e-mail <strong>{watchEmail}</strong> assim que o acesso à sua conta for liberado.</>}
                        disclaimer="Próximo da data de vencimento você irá receber no seu e-mail um link para efetuar a renovação da sua assinatura. Utilizando este link, você poderá efetuar um novo pagamento via Pix ou então utilizar algum outro método de pagamento."
                        invoice={_.get(subscription, 'invoice', {})}
                    />
                }
            </Box>
        </BillingLayout>
    );
}